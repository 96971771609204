import { store, ThriveApplication, Apollo } from '@thriveglobal/thrive-web-core'
import { Provider } from 'react-redux'
import Router from './routes'

const cacheOptions: Apollo.InMemoryCacheConfig = {
    typePolicies: {
        TodayQuery: {
            // Merge the top level Today queries. This prevents top level queries overwriting other top level today queries
            merge: true
        }
    }
}
const loadi18n = (lang: string) =>
    fetch(`${process.env.CF_SOURCE_URL}/lang/${lang}.json`)
        .then((res) => res.json())
        .catch(console.warn)
export default function Root() {
    return (
        <ThriveApplication
            messagesLoaders={[loadi18n]}
            apolloClientOptions={{ name: 'thrive-web-today', cacheOptions }}
            disableMaxWidth={true}
        >
            <Provider store={store}>
                <Router />
            </Provider>
        </ThriveApplication>
    )
}

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
